.App {
  text-align: center;
  
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* html, body, #reactapp, .App {
  height: 100%;
} */

/* body{
  display: flex;
  height: 100vh;
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}  */

html{
  font-size: 100%;
}

#bodyStyle{
  min-height: 100vh;
  background-size : cover;
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}

#logInBodyStyle{
  min-height: 1200px;
  background-size : cover;
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}

#loginform{

  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
}

#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.customButton{
  padding-top: 2rem;
  max-width: 100%;
}

.customButton button{
  border-radius: 25px;
  width: 80%;
  font-size: medium;
  height: fit-content;
  color: white;
  font-weight: 400;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}


.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
  
}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187,187,187,0.9);
}

.row text{
  align-self: start;
  padding-left: 4.5rem;
  color: red;
}


.row button{
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover{
  opacity: 0.8;
}

#button{
  padding-bottom: 1.5rem;
}

#iconGroup{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a{
  margin: 0 0.6rem;
}

#alternativeLogin {
   text-align: center;
   padding-top: 2rem;
   margin-top: 1.5rem;
}
